import React, { useContext } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Contact from './section-components/contact-page';
import Footer from './global-components/footer';
import { LanguageContext } from '../LanguageContext';

const ContactPage = () => {
    const { language, changeLanguage, translations } = useContext(LanguageContext);
    return <div>
        <Navbar />
        <PageHeader headertitle={translations.contact}  />
        <Contact />
        <Footer />
    </div>
}

export default ContactPage

